html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  color: $primary;
  background:  $tertiary;
  line-height: normal !important;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 1px grey;
  border-radius: 0;
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  // border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba($primary, 0.9);
}

/* Handle on press */
::-webkit-scrollbar-thumb:active {
  background: rgba($primary, 0.9);
}

:focus {
  outline: none;
}

a {
  transition: $transition;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none !important;
  }
}

.container {
  max-width: 1280px;
}



// ===== space between sections style =========

.section-padding {
  padding: 70px 0;
}

.sectionTitle {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;

  @include sm-down {
    font-size: 30px;
  }

}

// ===== Button style=========

.btn {
  font-size: 24px;
  font-weight: 400;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  text-transform: uppercase;

  &:first-child:active {
    background: inherit;
  }

  @include sm-down {
    font-size: 18px;
  }
}


.btn-sm {
  min-height: 30px !important;
  font-size: 20px;
  min-width: 150px;
}

.btn.btn-primary {
  background-color: $primary;
  color: $white;
  .span{
    padding-top: 6px;
  }

  @include sm-down {
    font-size: 18px !important;
    padding: 12px 10px;
    width: 100%;
  }


  &:hover {
    background-color: rgba($primary, 0.9);
  }

  svg {
    margin-right: 10px;
  }
}

.btn-outline-primary {
  background-color: $white;
  border: 1px solid $primary;

  &:hover,
  &:active {
    color: $primary;
    background-color: $white;
    span {
      color: $primary !important;
    }

  }
}

.btn-secondary {
  background-color: $secondary;
  color: $white;
  .span{
    padding-top: 6px;
  }

  @include sm-down {
    font-size: 18px !important;
    padding: 12px 10px;
    width: 100%;
  }


  &:hover {
    background-color: rgba($primary, 0.9);
  }

  svg {
    margin-right: 10px;
  }
}

.btn-outline-secondary,
.btn-outline-tertiary {
  background-color: transparent;
  border: 1px solid rgba(26, 26, 26, 1);
  color: rgba(26, 26, 26, 1);

  &:active,
  &:hover {
    color: $secondary;
    background-color: $white;
    span {
      color: $secondary !important;
    }
  }
}

.btn-outline-tertiary {
  color:$tertiary;
  border: 1px solid $tertiary !important;
}

// ==== sweet alert messagePopup styling ====

.swal2-popup {
  padding: $spacer * 1.5 $spacer !important;
  width: 25em !important;
  background: $secondary;

  @media only screen and (max-width: 767px) {
    padding: $spacer !important;
  }

  #swal2-title {
    color: $white;
  }

  .swal2-html-container {
    color: $white;
    font-size: 23px;
    line-height: 30px !important;
    font-weight: 400 !important;
  }

  .swal2-icon {
    margin: 0 auto;
  }

  .swal2-actions {
    column-gap: $spacer * 0.5;

    button {
      min-width: $spacer * 5;
      outline: none !important;
    }

    .swal2-confirm {
      background: $success !important;
      border-radius: 0 !important;

      &:hover {
        background: #689d23 !important;
      }
    }

    .swal2-cancel {
      background: $warning !important;
      color: white !important;
      border-radius: 0 !important;

      &:hover {
        background: rgba($warning, 0.7) !important;
      }
    }

    .swal2-deny {
      border-radius: 0 !important;

    }
  }

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.logo-family {
  font-family: $font-family-primary;
}

.info-text {
  font-family: $font-family-primary;
  color: $primary;
}

.cursor-pointer {
  cursor: pointer !important;
}

.language-flag {
  max-width: 50px;
  // max-height: 24px;
}

.max-btn {
  background: $primary;
  padding: 14px 11px;
  border-radius: 30px;
  color: $white;
  text-transform: uppercase;
  box-shadow: 0px 2px 4px rgba(12, 93, 158, 0.15), 0px 2px 6px rgba(39, 34, 36, 0.1);

  &:hover,
  &:active,
  &:focus {
    background: $primary !important;
    opacity: 0.9;
    color: $white;
  }
}

.extra-padding {
  padding: 30px !important;
}

// template headings
.heading {
  font-size: 60px;
  font-weight: 350;
  // font-family: $font-family-secondary;
  color: $secondary;

  @include sm-down {
    font-size: 34px;
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.loading {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

// modal
.modal-dialog {
  max-width: 400px;
  margin: 0 auto;

  .modal-content {
    background: $primary;
    border-radius: 10px;
    box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.3);
    transition: all 100ms ease-out 0s;
    overflow: hidden;

    .modal-header {
      padding: 12px 15px;
      justify-content: center;
      // background: rgba($secondary, 0.1);
      border: none !important;

      .modal-title {
        font-size: 25px;
        color: $white;
        font-weight: 500;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .modal-body {
      padding: 20px 15px;
      line-height: $spacer * 1.5;
      min-height: 250px;

      .btn-outline-secondary {
        background-color: rgba($secondary, 0.6);
        color: $white;

        span {
          width: 90%;
        }

        &:hover {
          background-color: rgba($secondary, 0.7);
        }
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}


// --- shimmer loading ---
.shimmer {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: $secondary;
  // background: $primary linear-gradient(to right, rgba($tertiary, 0.2) 0%, rgba($secondary, 0.5) 50%, rgba($tertiary, 0.2) 75%);
  background-size: 1000px 100%;
  color: transparent !important;
  border-color: transparent !important;
  border-radius: 4px;

  &.nobg {
    background-size: 0 !important;
  }

  &.outline-title-white,
  &.outline-title-secondary,
  .outline-title-white,
  .outline-title-secondary {
    -moz-text-stroke-color: transparent;
    -webkit-text-stroke-color: transparent;
  }

  .mat-select-arrow-wrapper,
  span,
  &svg-icon,
  & img,
  svg-icon,
  img {
    opacity: 0;

  }

  &.light {
    background: yellow linear-gradient(to right, #e4e6df 8%, #d9d1d1 18%, #e4e6df 33%);
    background-size: 1200px 100%;
  }
}


@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

.btn-outline-white:hover {
  background: transparent !important;
  border: 2px solid $white;
}

.dashTitle {
  position: relative;
  letter-spacing: 1.5px;
  font-size: 20px;

  &::before,
  &::after {
    content: '';
    width: 20%;
    height: 1px;
    background: $grey;
    position: absolute;
    left: 0;
    top: 50%;

    @include sm-down {
      width: 20%;
    }
  }

  &::after {
    right: 0;
    left: auto;
  }
}

.tab-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    width: 150px;
    min-height: 42px;
    padding: 6px;
    border: none;
    color: $white;
    border: 1px solid $tertiary;
    background: $tertiary;
    letter-spacing: 1px;
    span{

    }
    &.selected {
      border: none;
      opacity: 1;     
      background: $secondary;
      color: $white;
    }
  }
}

.dropdown-toggle {
  width: 100%;

  &::after {
    position: absolute;
    right: 12px;
    opacity: 0;
  }

  .flag-icon {
    border-radius: 50%;
    overflow: hidden;
    margin-left: 3px;
    min-width: 20px;
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  .dropdown {
    cursor: pointer;
  }
}

.dropdown-menu {
  width: 100%;
  max-height: 270px;
  overflow-y: auto;
  padding: 0;
  background-color: $primary;
  border: 2px solid #8080801c;

  @include sm-down {
    max-height: 210px;
  }

  li {
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      border: 1px solid $white;
      overflow: hidden;
      margin-right: 10px;
      min-width: 20px;
      height: 20px;
      width: 20px;
      object-fit: cover;
    }

    span {
      color: $primary
    }
    a{
      color: $white;
      &:hover,
      &:active,
      &:focus {color: $white;}
    } 
  }
}


.owl-theme .owl-dots .owl-dot span {
  background: $light !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $primary !important;
}
