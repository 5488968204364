@import './variables';
$utilities: (
  'font-weight': (
    property: font-weight,
    class: fw,
    values: (
      lighter: $font-weight-lighter,
      light: $font-weight-light,
      regular: $font-weight-regular,
      medium: $font-weight-medium,
      semibold: $font-weight-semibold,
      bold: $font-weight-bold,
      bolder: $font-weight-bolder,
    ),
  ),
  'width': (
    property: width,
    class: w,
    values: (
      100: 100%,
      90: 90%,
      80: 80%,
      70: 70%,
      60: 60%,
      50: 50%,
      40: 40%,
      30: 30%,
      20: 20%,
      10: 10%,
    ),
  ),
  'rounded': (
    property: border-radius,
    class: rounded,
    values: (
      null: $border-radius,
      0: 0,
      1: $border-radius-sm,
      2: $border-radius,
      3: $border-radius-lg,
      4: $border-radius-xl,
      circle: 50%,
      pill: $border-radius-pill,
    ),
  ),
) !default;


// modal width
@each $size,
$value in $grid-breakpoints {
  .modal-#{$size} {
    max-width: $value !important;
  }
}


// object width in pixel
@each $width in $object-sizes {
	.wx-#{$width} {
		width: #{$width + 'px'} !important;
	}
}

@each $width in $object-sizes {
	.mwx-#{$width} {
		max-width: #{$width + 'px'} !important;
	}
}

// object height in pixel
@each $height in $object-sizes {
	.wh-#{$height} {
		height: #{$height + 'px'} !important;
	}
}

// object width in percent
@each $width in $object-sizes {
  .wd-#{$width} {
    width: #{$width + '%'};
	}
}

// object width in percent as per break point

@each $name, $breakpoint in $grid-breakpoints {
  @media(min-width: $breakpoint) {
    @each $width in $object-sizes {
      .wd-#{$name}-#{$width} {
        width: #{$width + '%'} !important;
      }
    }
  }
}

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    @each $width in $object-sizes {
      .wd-#{$name}-#{$width} {
        width: #{$width + '%'};
      }
    }
  }
}

// font sizes
$font-size: 70;

@for $font from 1 through $font-size {
  .font-#{$font} {
    font-size: $font + px !important;
  }
}

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    @for $font from 1 through $font-size {
      .font-#{$name}-#{$font} {
        font-size: $font + px !important;
      }
    }
  }
}

// modal width
@each $size, $value in $grid-breakpoints {
  .modal-#{$size} {
    max-width: $value !important;
  }
}

@each $color, $value in $theme-colors {
  @each $color2, $value2 in $theme-colors {
    .tg-#{$color}-#{$color2} {
      background: -webkit-linear-gradient($value, $value2);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
