@import url('https://fonts.googleapis.com/css2?family=+Plex+Mono:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'EastSeaDokdo';
  src: url('./assets/fonts/EastSeaDokdo.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@import 'assets/scss/variables';

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* You can add global styles to this file, and also import other style files */

@import 'assets/scss/responsive';
@import 'assets/scss/utilities';
@import 'assets/scss/reboot';
@import 'assets/scss/all';
@import "./node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "./node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
